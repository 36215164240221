var _0x48cc = [
  '\x74\x72\x69\x6D',
  '\x70\x72\x6F\x74\x6F\x74\x79\x70\x65',
  '\x66\x75\x6E\x63\x74\x69\x6F\x6E',
  '',
  '\x72\x65\x70\x6C\x61\x63\x65',
  '\x6D\x65\x6D\x6F\x69\x7A\x65',
  '\x6D\x75\x6C\x74\x69\x70\x6C\x79',
  '\x74\x6F\x42\x69\x67\x49\x6E\x74\x65\x67\x65\x72',
  '\x67\x65\x74\x58',
  '\x67\x65\x74\x59',
  '\x73\x75\x62\x73\x74\x72\x69\x6E\x67',
  '\x67\x65\x74\x51',
  '\x67\x65\x74\x43\x75\x72\x76\x65',
  '\x67\x65\x74\x41',
  '\x67\x65\x74\x42',
  '\x67\x65\x74\x47',
  '\x67\x65\x74\x4E',
  '\x69\x6E\x69\x74',
  '\x73\x65\x63\x70\x31\x32\x38\x72\x31',
  '\x66\x72\x6F\x6D\x42\x69\x67\x49\x6E\x74\x65\x67\x65\x72',
  '\x73\x75\x62\x74\x72\x61\x63\x74',
  '\x62\x69\x74\x4C\x65\x6E\x67\x74\x68',
  '\x61\x64\x64',
  '\x6D\x6F\x64',
  '\x6C\x65\x6E\x67\x74\x68',
  '\x73\x75\x62\x73\x74\x72',
  '\x65\x6E\x63\x72\x79\x70\x74\x54\x6F\x4D\x46',
  '\x65\x6E\x63\x72\x79\x70\x74\x54\x6F\x41\x70\x70',
  '\x30',
  '\x30\x34',
  '\x69\x6E\x66\x69\x6E\x69\x74\x79',
  '\x30\x66\x30\x65\x30\x64\x30\x63\x30\x62\x30\x61\x30\x39\x30\x38\x30\x37\x30\x36\x30\x35\x30\x34\x30\x33\x30\x32\x30\x31\x30\x30',
  '\x70\x61\x72\x73\x65',
  '\x48\x65\x78',
  '\x65\x6E\x63',
  '\x4C\x61\x74\x69\x6E\x31',
  '\x50\x6B\x63\x73\x37',
  '\x70\x61\x64',
  '\x43\x42\x43',
  '\x6D\x6F\x64\x65',
  '\x65\x6E\x63\x72\x79\x70\x74',
  '\x41\x45\x53',
  '\x63\x68\x61\x72\x43\x6F\x64\x65\x41\x74',
  '\x66\x72\x6F\x6D\x43\x68\x61\x72\x43\x6F\x64\x65',
  '\x64\x65\x63\x72\x79\x70\x74\x46\x72\x6F\x6D\x41\x70\x70',
  '\x42\x61\x73\x65\x36\x34',
  '\x63\x72\x65\x61\x74\x65',
  '\x43\x69\x70\x68\x65\x72\x50\x61\x72\x61\x6D\x73',
  '\x6C\x69\x62',
  '\x64\x65\x63\x72\x79\x70\x74',
  '\x70\x75\x73\x68',
  '\x6A\x6F\x69\x6E',
  '\x65\x6E\x63\x72\x79\x70\x74\x48\x61\x73\x68\x50\x61\x73\x73\x77\x6F\x72\x64',
  '\x70\x61\x73\x73\x77\x6F\x72\x64\x20\x69\x73\x20\x65\x6D\x70\x74\x79',
  '\x73\x61\x6C\x74\x20\x69\x73\x20\x65\x6D\x70\x74\x79',
  '\x74\x6F\x55\x70\x70\x65\x72\x43\x61\x73\x65',
  '\x30\x31',
  '\x73\x74\x72\x69\x6E\x67\x69\x66\x79',
  '\x73\x69\x67\x6E',
  '\x67\x65\x74\x56\x65\x72\x73\x69\x6F\x6E',
  '\x34',
  '\x44\x4C\x45\x43\x43',
];
var DLECC = function() {
  var _0xa209x2;
  var _0xa209x3;
  var _0xa209x4;
  var _0xa209x5;
  var _0xa209x6;
  var _0xa209x7;
  var _0xa209x8;
  if (typeof String[_0x48cc[1]][_0x48cc[0]] !== _0x48cc[2]) {
    String[_0x48cc[1]][_0x48cc[0]] = function() {
      return this[_0x48cc[4]](/^\s+|\s+$/g, _0x48cc[3]);
    };
  }
  Function[_0x48cc[1]][_0x48cc[5]] = function() {
    var _0xa209x9 = this,
      _0xa209xa = {};
    return function(_0xa209xb) {
      if (_0xa209xb in _0xa209xa) {
        return _0xa209xa[_0xa209xb];
      } else {
        return (_0xa209xa[_0xa209xb] = _0xa209x9(_0xa209xb));
      }
    };
  };
  function _0xa209xc(_0xa209xd) {
    var _0xa209xe = new Date();
    var _0xa209xf = _0xa209x1b();
    var _0xa209x10 = _0xa209x2d(_0xa209xd);
    var _0xa209x4 = new BigInteger(_0xa209x2);
    var _0xa209x11 = _0xa209x10[_0x48cc[6]](_0xa209x4);
    var _0xa209x12 = new Date();
    var _0xa209x13 = _0xa209x11[_0x48cc[8]]()
      [_0x48cc[7]]()
      .toString();
    var _0xa209x14 = _0xa209x11[_0x48cc[9]]()
      [_0x48cc[7]]()
      .toString();
    var _0xa209x15 = _0xa209x13[_0x48cc[10]](0, 16);
    return _0xa209x15;
  }
  var _0xa209x16 = _0xa209xc[_0x48cc[5]]();
  function _0xa209x17(_0xa209x18) {
    var _0xa209x19 = getSECCurveByName(_0xa209x18);
    _0xa209x3 = _0xa209x19[_0x48cc[12]]()
      [_0x48cc[11]]()
      .toString();
    _0xa209x4 = _0xa209x19[_0x48cc[12]]()
      [_0x48cc[13]]()
      [_0x48cc[7]]()
      .toString();
    _0xa209x5 = _0xa209x19[_0x48cc[12]]()
      [_0x48cc[14]]()
      [_0x48cc[7]]()
      .toString();
    _0xa209x6 = _0xa209x19[_0x48cc[15]]()
      [_0x48cc[8]]()
      [_0x48cc[7]]()
      .toString();
    _0xa209x7 = _0xa209x19[_0x48cc[15]]()
      [_0x48cc[9]]()
      [_0x48cc[7]]()
      .toString();
    _0xa209x8 = _0xa209x19[_0x48cc[16]]().toString();
    _0xa209x2 = _0x48cc[3];
  }
  this[_0x48cc[17]] = function() {
    _0xa209x17(_0x48cc[18]);
    var _0xa209x1a = _0xa209x1d();
    _0xa209x2 = _0xa209x1a.toString();
    return _0xa209x21();
  };
  function _0xa209x1b() {
    return new ECCurveFp(new BigInteger(_0xa209x3), new BigInteger(_0xa209x4), new BigInteger(_0xa209x5));
  }
  function _0xa209x1c(_0xa209xf) {
    return new ECPointFp(
      _0xa209xf,
      _0xa209xf[_0x48cc[19]](new BigInteger(_0xa209x6)),
      _0xa209xf[_0x48cc[19]](new BigInteger(_0xa209x7)),
    );
  }
  function _0xa209x1d() {
    var _0xa209x1e = new SecureRandom();
    var _0xa209x1f = new BigInteger(_0xa209x8);
    var _0xa209x20 = _0xa209x1f[_0x48cc[20]](BigInteger.ONE);
    var _0xa209x1a = new BigInteger(_0xa209x1f[_0x48cc[21]](), _0xa209x1e);
    return _0xa209x1a[_0x48cc[23]](_0xa209x20)[_0x48cc[22]](BigInteger.ONE);
  }
  function _0xa209x21() {
    var _0xa209xe = new Date();
    var _0xa209xf = _0xa209x1b();
    var _0xa209x22 = _0xa209x1c(_0xa209xf);
    var _0xa209x4 = new BigInteger(_0xa209x2);
    var _0xa209x10 = _0xa209x22[_0x48cc[6]](_0xa209x4);
    var _0xa209x12 = new Date();
    return _0xa209x29(_0xa209x10);
  }
  function _0xa209x23(_0xa209x24) {
    var _0xa209x25 = _0x48cc[3];
    for (var _0xa209x26 = _0xa209x24[_0x48cc[24]] - 1; _0xa209x26 >= 0; _0xa209x26--) {
      _0xa209x25 += _0xa209x24[_0x48cc[25]](_0xa209x26, 1);
    }
    return _0xa209x25;
  }
  this[_0x48cc[26]] = function(_0xa209x27, _0xa209x28) {
    return _0xa209x2f(_0xa209x27, true, _0xa209x28);
  };
  this[_0x48cc[27]] = function(_0xa209x27, _0xa209x28) {
    return _0xa209x2f(_0xa209x27, false, _0xa209x28);
  };
  function _0xa209x29(_0xa209x10) {
    var _0xa209x2a = _0xa209x10[_0x48cc[8]]()
      [_0x48cc[7]]()
      .toString(16);
    var _0xa209x2b = _0xa209x10[_0x48cc[9]]()
      [_0x48cc[7]]()
      .toString(16);
    var _0xa209xf = _0xa209x1b();
    var _0xa209x2c = _0xa209xf[_0x48cc[11]]().toString(16)[_0x48cc[24]];
    if (_0xa209x2c % 2 != 0) {
      _0xa209x2c++;
    }
    while (_0xa209x2a[_0x48cc[24]] < _0xa209x2c) {
      _0xa209x2a = _0x48cc[28] + _0xa209x2a;
    }
    while (_0xa209x2b[_0x48cc[24]] < _0xa209x2c) {
      _0xa209x2b = _0x48cc[28] + _0xa209x2b;
    }
    return _0x48cc[29] + _0xa209x2a + _0xa209x2b;
  }
  function _0xa209x2d(_0xa209x24) {
    switch (parseInt(_0xa209x24[_0x48cc[25]](0, 2), 16)) {
      case 0:
        return this[_0x48cc[30]];
      case 2:
      case 3:
        return null;
      case 4:
      case 6:
      case 7:
        var _0xa209x2e = (_0xa209x24[_0x48cc[24]] - 2) / 2;
        var _0xa209x2a = _0xa209x24[_0x48cc[25]](2, _0xa209x2e);
        var _0xa209x2b = _0xa209x24[_0x48cc[25]](_0xa209x2e + 2, _0xa209x2e);
        var _0xa209xf = _0xa209x1b();
        return new ECPointFp(
          _0xa209xf,
          _0xa209xf[_0x48cc[19]](new BigInteger(_0xa209x2a, 16)),
          _0xa209xf[_0x48cc[19]](new BigInteger(_0xa209x2b, 16)),
        );
      default:
        return null;
    }
  }
  function _0xa209x2f(_0xa209x30, _0xa209x31, _0xa209x28) {
    var _0xa209x15 = _0xa209x16(_0xa209x28);
    var _0xa209x32 = CryptoJS[_0x48cc[34]][_0x48cc[33]][_0x48cc[32]](_0x48cc[31]);
    if (_0xa209x31) {
      _0xa209x15 = _0xa209x23(_0xa209x15);
    }
    var _0xa209x33 = CryptoJS[_0x48cc[34]][_0x48cc[35]][_0x48cc[32]](_0xa209x15);
    var _0xa209x34 = CryptoJS[_0x48cc[41]][_0x48cc[40]](_0xa209x30, _0xa209x33, {
      iv: _0xa209x32,
      padding: CryptoJS[_0x48cc[37]][_0x48cc[36]],
      mode: CryptoJS[_0x48cc[39]][_0x48cc[38]],
    });
    return _0xa209x34;
  }
  function _0xa209x35(_0xa209x36) {
    var _0xa209x37 = _0x48cc[3];
    for (var _0xa209x26 = 0; _0xa209x26 < _0xa209x36[_0x48cc[24]]; _0xa209x26++) {
      _0xa209x37 += String[_0x48cc[43]](0xff ^ _0xa209x36[_0x48cc[42]](_0xa209x26));
    }
    return _0xa209x37;
  }
  this[_0x48cc[44]] = function _0xa209x38(_0xa209x39, _0xa209x28) {
    var _0xa209xe = new Date();
    var _0xa209xf = _0xa209x1b();
    var _0xa209x10 = _0xa209x2d(_0xa209x28);
    var _0xa209x4 = new BigInteger(_0xa209x2);
    var _0xa209x11 = _0xa209x10[_0x48cc[6]](_0xa209x4);
    var _0xa209x12 = new Date();
    var _0xa209x13 = _0xa209x11[_0x48cc[8]]()
      [_0x48cc[7]]()
      .toString();
    var _0xa209x14 = _0xa209x11[_0x48cc[9]]()
      [_0x48cc[7]]()
      .toString();
    var _0xa209x32 = CryptoJS[_0x48cc[34]][_0x48cc[33]][_0x48cc[32]](_0x48cc[31]);
    var _0xa209x15 = _0xa209x13[_0x48cc[10]](0, 16);
    _0xa209x15 = _0xa209x35(_0xa209x15);
    var _0xa209x33 = CryptoJS[_0x48cc[34]][_0x48cc[35]][_0x48cc[32]](_0xa209x15);
    var _0xa209x3a = CryptoJS[_0x48cc[48]][_0x48cc[47]][_0x48cc[46]]({
      ciphertext: CryptoJS[_0x48cc[34]][_0x48cc[45]][_0x48cc[32]](_0xa209x39),
    });
    var _0xa209x3b = CryptoJS[_0x48cc[41]][_0x48cc[49]](_0xa209x3a, _0xa209x33, { iv: _0xa209x32 });
    return _0xa209x3b.toString(CryptoJS[_0x48cc[34]].Utf8);
  };
  function _0xa209x3c(_0xa209x3d) {
    return _0xa209x3d[_0x48cc[4]](/^\s+|\s+$/gm, _0x48cc[3]);
  }
  function _0xa209x3e(_0xa209x3f) {
    for (var _0xa209x40 = [], _0xa209x26 = 0; _0xa209x26 < _0xa209x3f[_0x48cc[24]]; _0xa209x26++) {
      _0xa209x40[_0x48cc[50]]((_0xa209x3f[_0xa209x26] >>> 4).toString(16));
      _0xa209x40[_0x48cc[50]]((_0xa209x3f[_0xa209x26] & 0xf).toString(16));
    }
    return _0xa209x40[_0x48cc[51]](_0x48cc[3]);
  }
  function _0xa209x41(_0xa209x40) {
    for (var _0xa209x3f = [], _0xa209x19 = 0; _0xa209x19 < _0xa209x40[_0x48cc[24]]; _0xa209x19 += 2) {
      _0xa209x3f[_0x48cc[50]](parseInt(_0xa209x40[_0x48cc[25]](_0xa209x19, 2), 16));
    }
    return _0xa209x3f;
  }
  this[_0x48cc[52]] = function _0xa209x42(_0xa209x15, _0xa209x43, _0xa209x44) {
    if (_0xa209x15 == null || _0xa209x15[_0x48cc[0]]()[_0x48cc[24]] == 0) {
      alert(_0x48cc[53]);
      return null;
    }
    if (_0xa209x43 == null || _0xa209x43[_0x48cc[0]]()[_0x48cc[24]] == 0) {
      alert(_0x48cc[54]);
      return null;
    }
    var _0xa209x24 = _0xa209x3c(_0xa209x43[_0x48cc[55]]());
    var _0xa209x45 = CryptoJS.SHA1(_0xa209x24)
      .toString()
      [_0x48cc[55]]();
    var _0xa209x46 = _0xa209x15 + _0xa209x45;
    var _0xa209x47 = _0x48cc[56] + CryptoJS[_0x48cc[34]][_0x48cc[45]][_0x48cc[57]](CryptoJS.SHA256(_0xa209x46));
    return _0xa209x2f(_0xa209x47, false, _0xa209x44);
  };
  this[_0x48cc[58]] = function _0xa209x48(_0xa209x46, _0xa209x44) {
    if (_0xa209x46 == null || _0xa209x44 == 0) {
      return null;
    }
    var _0xa209x24 = _0xa209x3c(_0xa209x46);
    var _0xa209x45 = CryptoJS.SHA256(_0xa209x24);
    return _0xa209x2f(_0xa209x45, false, _0xa209x44);
  };
  this[_0x48cc[59]] = function _0xa209x49() {
    return _0x48cc[60];
  };
};
window[_0x48cc[61]] = new DLECC();
